<template>
  <div v-if="data">
    <v-card outlined v-for="item in data" :key="item.id" class="my-2 pa-3" hover>
      <v-card-title>
        {{ item.title }}
        <v-spacer></v-spacer>
        <v-icon color="primary" @click="$emit('edit', item.id)">mdi-pencil</v-icon>
        <v-icon color="error" @click="$emit('delete', item.id)">mdi-trash-can</v-icon>
      </v-card-title>
      <v-card-subtitle>
        <span v-if="item.createdAt">
          {{ item.createdAt.toDate() | fromNow }}
        </span>
      </v-card-subtitle>
      <v-card-text>
        {{ item.html }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Email from '../../../services/email';
import Moment from 'moment';
const email = new Email();
export default {
  data() {
    return {
      data: [],
      success: '',
      snackbar: false,
      message: '',
      type: 'success'
    };
  },
  created() {
    this.getData();
  },
  filters: {
    fromNow(date) {
      return Moment(date).fromNow();
    }
  },
  methods: {
    getData() {
      email.list({ type: email.types.EMAIL_HTML }, res => {
        const data = [];
        res.forEach(item => {
          data.push({ ...item.data(), id: item.id });
        });
        this.data = data;
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped lang="scss">
</style>

