<script>
import BaseForm from './BaseForm.vue';
import Email from '../../../services/email';
const email = new Email();
export default {
  extends: BaseForm,
  props: {
    id: {
      type: String,
      required: true
    },
    submitLabel: {
      type: String,
      default: 'Update'
    }
  },
  watch: {
    id: {
      handler(id) {
        if (!id) {
          return;
        }
        email.get(id).then(res => {
          this.formData = res.data();
        });
      },
      immediate: true
    }
  },
  methods: {
    submit() {
      this.loading = true;
      this.success = '';
      this.error = '';
      email
        .updateEmailHtml(this.id, this.formData)
        .then(() => {
          this.success = 'HTML updated';
          this.formData = {};
          this.$refs.form.resetValidation();
          this.$emit('success');
        })
        .catch(error => {
          this.error = error;
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>
