<template>
  <div>
    <v-container fluid>
      <v-dialog v-model="dialogs.delete" width="300">
        <BaseConfirmation small v-model="dialogs.delete" @confirm="deleteItem()">
          <template #head>
            <v-alert type="error" v-if="deleteError">
              {{ deleteError }}
            </v-alert>
          </template>
          <template> Delete mailing list? </template>
        </BaseConfirmation>
      </v-dialog>
      <v-row>
        <v-col cols="12" sm="6" id="form">
          <v-card class="mx-auto card-title--primary" hover>
            <v-tabs centered background-color="primary" dark v-model="tab">
              <v-tab class="text-capitalize"> Add </v-tab>
              <v-tab :disabled="!id" class="text-capitalize"> Update </v-tab>
            </v-tabs>
            <div class="text-center ma-3 pa-3" v-if="!dialogs.form">
              <v-btn
                color="primary"
                text
                class="text-capitalize"
                @click="dialogs.form = !dialogs.form"
              >
                Show Form
                <v-icon>{{ dialogs.form ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></v-btn
              >
            </div>
            <v-tabs-items v-model="tab" v-show="dialogs.form">
              <v-tab-item>
                <v-container>
                  <Create />
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container>
                  <Update
                    :key="id"
                    :id="id"
                    @success="onUpdateSuccess()"
                    @cancel="onUpdateSuccess()"
                  />
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <List @edit="onClickEdit" @delete="onClickDelete" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Create from './EmailHtml/Create.vue';
import Update from './EmailHtml/Update.vue';
import BaseConfirmation from '../Core/Base/Confirmation.vue';
import List from './EmailHtml/List.vue';
import Email from '../../services/email';
const email = new Email();
export default {
  components: {
    Create,
    Update,
    BaseConfirmation,
    List
  },
  data() {
    return {
      tab: null,
      id: '',
      dialogs: {
        delete: false,
        form: false
      },
      deleteError: ''
    };
  },
  computed: {
    isXs() {
      return this.$vuetify.breakpoint.xs;
    }
  },
  watch: {
    isXs: {
      handler(newValue) {
        if (newValue) {
          this.dialogs.form = false;
        } else {
          this.dialogs.form = true;
        }
      },
      immediate: true
    }
  },
  methods: {
    onClickEdit(id) {
      this.id = id;
      this.tab = 1;
      this.dialogs.form = true;
      document.getElementById('form').scrollIntoView({ behavior: 'smooth' });
    },
    onClickDelete(id) {
      this.tab = 0;
      this.id = id;
      this.dialogs.delete = true;
    },
    deleteItem() {
      email
        .delete(this.id)
        .then(() => {
          this.id = '';
          this.dialogs.delete = false;
        })
        .catch(error => {
          this.deleteErorr = error.message;
        });
    },
    onUpdateSuccess() {
      this.tab = 0;
      this.id = '';
      if (this.isXs) {
        this.dialogs.form = false;
      }
    }
  }
};
</script>

<style lang="scss">
</style>
