<script>
import BaseForm from './BaseForm.vue';
import Email from '../../../services/email';
const email = new Email();
export default {
  extends: BaseForm,
  methods: {
    submit() {
      this.loading = true;
      this.success = '';
      this.error = '';
      email
        .createEmailHtml(this.formData)
        .then(() => {
          this.success = 'Email HTML created';
          setTimeout(() => {
            this.success = '';
          }, 5000);
          this.reset();
        })
        .catch(error => {
          this.error = error;
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.reset();
    }
  }
};
</script>
