<template>
  <div>
    <EmailHtml />
  </div>
</template>

<script>
import EmailHtml from '../../../components/Admin/EmailHtml.vue';
export default {
  components: {
    EmailHtml
  }
};
</script>

<style>
</style>
