import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('div',_vm._l((_vm.data),function(item){return _c(VCard,{key:item.id,staticClass:"my-2 pa-3",attrs:{"outlined":"","hover":""}},[_c(VCardTitle,[_vm._v(" "+_vm._s(item.title)+" "),_c(VSpacer),_c(VIcon,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('edit', item.id)}}},[_vm._v("mdi-pencil")]),_c(VIcon,{attrs:{"color":"error"},on:{"click":function($event){return _vm.$emit('delete', item.id)}}},[_vm._v("mdi-trash-can")])],1),_c(VCardSubtitle,[(item.createdAt)?_c('span',[_vm._v(" "+_vm._s(_vm._f("fromNow")(item.createdAt.toDate()))+" ")]):_vm._e()]),_c(VCardText,[_vm._v(" "+_vm._s(item.html)+" ")])],1)}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }