<template>
  <div>
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit()">
      <v-text-field
        :rules="rules.requiredRule"
        label="Title"
        v-model="formData.title"
      ></v-text-field>
      <v-textarea v-model="formData.html" label="HTML" auto-grow rows="5"> </v-textarea>
      <AlertMessage :success="success" :error="error" />
      <BaseFormAction
        class="mt-2"
        :x-large="false"
        :loading="loading"
        :submit-label="submitLabel"
        @click="onSubmit"
        @cancel="onCancel"
      />
    </v-form>
  </div>
</template>

<script>
import ruleMixin from '@/mixins/rules';
import AlertMessage from '../../Core/AlertMessage.vue';
import User from '../../../services/user';
const user = new User();
export default {
  props: {
    value: {
      type: Object,
      default: null
    },
    submitLabel: {
      type: String,
      default: 'Add'
    }
  },
  mixins: [ruleMixin],
  components: {
    AlertMessage
  },
  data() {
    return {
      formData: {},
      valid: true,
      success: '',
      error: '',
      loading: false
    };
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue) {
          this.formData = {};
        }
      }
    }
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate();
      if (this.valid) {
        this.submit();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    onCancel() {
      this.cancel();
      this.$emit('cancel');
    }
  }
};
</script>


<style>
</style>
